<template>
  <div></div>
</template>

<script>
import axios from 'axios'

import { mapState, mapGetters } from 'vuex'
export default {
  name: 'DeviceRegistration',
  computed: {
    ...mapState([
      'capability',
      'sidebarShow',
      'sidebarMinimize'
    ]),
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
  },
  mounted() {
    const bridge_id = this.$route.query.bridgeId;
    const event_name = Buffer.from(this.$route.query.eventName, 'base64').toString('utf-8');
    const ignoring_second = this.$route.query.ignoringSecond;

    axios.post('/api/alarm/ignoring-interval/', {bridge_id, event_name, ignoring_second})
      .then(() => {
        //this.$router.push('/setup/event-page');
        this.$notify.success({
          title: this.$t('cloud.title.success'),
          message: this.$t('cloud.title.request_done'),
          offset: 30
        });
        this.$router.push('/ignoring-interval');
      })
      .catch(error =>{
        console.error(error);
        this.$notify.error({
          title: this.$t('cloud.title.error'),
          message: this.$t('cloud.title.request_fail'),
          offset: 30
        });
        this.$router.push('/');
      })
  }
}
</script>
